<template>
  <div ref="container">
    <loading :active.sync="isLoading" :full-poge="false"></loading>
    <b-card-title class="card-title mx-1" title-tag="div">
      <b-row no-gutters>
        <b-col sm="12" md="9" class="display: flex">

          <button v-if="isAdminOrStaffOrReseller" class="btn btn-sm btn-primary mr-1 mb-1" @click="onExtraCharge" title="Crear Cargo Extra">
            <feather-icon size="15" icon="PlusSquareIcon" class="mr-50" />
            <span style="font-size: 15px">Crear Cargo Extra</span>
          </button>

          <button class="btn btn-sm btn-primary mr-1 mb-1" @click="onFilter" title="Filtrar operaciones">
            <feather-icon size="15" icon="FilterIcon" class="mr-1" />
            <span style="font-size: 15px">Filtrar</span>
          </button>


          <button class="btn btn-sm btn-primary mr-1 mb-1" @click="onPay"
            v-if="operations.length > 0 && isAdminOrStaffOrReseller && customer && client.type === 'post-pago' && operations['0']['type'] != 'pre-pago'" title="Crear un balance">
            <feather-icon size="15" icon="DollarSignIcon" class="mr-50" />
            <span style="font-size: 15px">Crear Balance</span>
          </button>


          <button
            v-b-tooltip.hover
            title="Pagar operaciones"
            class="btn btn-sm btn-primary mr-1 mb-1"
            @click="onPayMercadoPago"
            v-if="operations.length > 0 && isCustomer"
          >
            <feather-icon size="15" icon="CreditCardIcon" class="mr-50" />
            <span style="font-size: 15px" v-if="operations.length > 1">
              Pagar ({{ operations.length }})
            </span>
            <span style="font-size: 15px" v-else> Pagar Operación </span>
          </button>


        </b-col>
        <b-col sm="12" md="3">
          <filter-bar-component ref="search" v-on:search="onSearch(null, true)"></filter-bar-component>
        </b-col>



      </b-row>
    </b-card-title>
    <b-card-body class="card-body py-2 px-0">
      <full-table-component ref="fulltable" :fields="fields" :perPage="100" @selectTo="selectTo"
        @onLoadSuccess="onLoadSuccess" url="/transactions/Charge">
        <template slot="buttons-slot" slot-scope="props">
          <menu-extra-charge 
          :item="props.rowData" 
          @onView="onView" 
          @onExtraCharge="onExtraCharge"
          @onDelete="onDelete" 
          />
        </template>
      </full-table-component>
    </b-card-body>
  </div>
</template>
<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FilterBarComponent from "@/layouts/components/FilterBarComponent";
import FullTableComponent from "@/layouts/components/FullTableComponent";
// import ConfirmComponent from "@/layouts/components/ConfirmComponent";
// import Checkbox2Component from "@/layouts/components/Checkbox2Component";

import UserService from "@/services/UserService";
import UtilService from "@/services/UtilService";
import OperationService from "@/services/OperationService";
import AlertService from "@/services/AlertService";

import OperationExtraChargeView from "./OperationExtraChargeView";
import FilterComponent from "./components/FilterComponent";
import MenuExtraCharge from './components/MenuExtraCharge.vue'
import moment from "moment";

// NUEVO CARGO EXTRA
import ExtraCharge from "./ExtraCharge";

import PayComponent from "./PayComponentExtraCharge";

import MercadoPagoService from "@/services/MercadoPagoService";




export default {
  name: "OperationHistory",
  components: {
    // FilterComponent,
    FilterBarComponent,
    FullTableComponent,
    MenuExtraCharge
    // ConfirmComponent,
    // Checkbox2Component,
  },


  
  data() {
    
    const fields = [
      {
        name: "__checkbox2",
      },
      {
        name: "id",
        title: "Id",
        sortField: "id",
      },
      {
        name: "created_at",
        title: "Creado",
        sortField: "created_at",
        formatter: (value) => {
          return UtilService.date(value);
        },
      },
      {
        name: "title",
        title: "Titulo",
        sortField: "title",
      },

      {
        name: "brand",
        title: "Tienda",
        sortField: "brand",
      },

      {
        name: "type",
        title: "Tipo",
        sortField: "type",
      },
      {
        name: "status_payment",
        title: "Pago",
        sortField: "status_payment",
      },
      {
        name: "price",
        title: "Total",
        sortField: "price",
        formatter: (value) => {
          return this.$options.filters.currency(value);
        },
      },
      {
        name: "buttons-slot",
        title: "Acciones",
        titleClass: "text-right",
        dataClass: "text-right align-middle",
      },
    ];
    const client = UserService.user();
    return {
      client: client,
      isLoading: false,
      fullPage: true,
      customer: null,
      date_from: null,
      date_to: null,
      fields: fields,
      operations: [],
      isAdmin: UserService.isAdminOrStaff(),
      isAdminOrStaffOrReseller: UserService.isAdminOrStaffOrReseller(),
      operationsLabel: [],
      isCustomer: UserService.isCustomer(),
    };
  },
  methods: {


    /**
     *
     */
    onLoadSuccess(response) {
      this.response = response;
    },

    /**
     *
     */
    async onDelete(item) {
      const result = await AlertService.delete(
        "¿Estás seguro?",
        "Estas seguro de eliminar el cargo extra con el id " + item.id
      );
      if (result.value) {
        this.isLoading = true;
        OperationService.deleteCharge(item.id)
          .then(() => {
            this.isLoading = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Eliminar`,
                text: `Registro eliminado`,
                icon: "InfoIcon",
                variant: "info",
              },
            });
            this.$refs.fulltable.refresh();
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Eliminar`,
                text: `Error al tratar de eliminar este elemento`,
                icon: "AlertOctagonIcon",
                variant: "danger",
              },
            });
          });
      }
    },

    /**
     *
     */
    selectTo(item) {
      if (this.customer) {
        this.operations = item.filter((d) => {
          return d.user_id === this.customer.id;
        });
        this.$store.commit("changeExtra", this.operations);
      } else {
        // if (this.isCustomer && this.user.type === "pre-pago") {
        this.operations = item;
        // }
      }

      this.operationsLabel = item.map((d) => d.id);
    },




    onSearch(event, clear) {
      const search = this.$refs.search ? this.$refs.search.filterText : "";
      const _from = this.date_from ? moment(this.date_from) : null;
      const _to = this.date_to ? moment(this.date_to) : null;
      this.$refs.fulltable.search(
        search,
        {
          customer: this.customer ? this.customer.id : null,
          from: this.date_from ? _from.format("YYYY-MM-DD") : null,
          to: this.date_to ? _to.format("YYYY-MM-DD") : null,
        },
        clear
      );
    },



    onFilter() {
      this.$modal.show(
        FilterComponent,
        {
          customer: this.customer,
          from: this.date_from,
          to: this.date_to,
          onClose: {
            handler: () => { },
          },
          onFilter: {
            handler: (item) => {
              this.customer = item.customer;
              this.$store.commit("changeCustomer", this.customer);
              this.date_from = item.from;
              this.date_to = item.to;
              if (item.clear) {
                this.$refs.fulltable.clear();
              } else {
                this.onSearch(null, true);
              }
            },
          },
        },
        {
          adaptive: true,
          width: "50%",
          height: "auto",
        }
      );
    },
    async onView(item) {
      debugger
      this.$modal.show(
        OperationExtraChargeView,
        {
          item,
        },
        {
          adaptive: true,
          width: "70%",
          height: "auto",
        }
      );
    },
    async onRestore(item) {
      const result = await AlertService.delete(
        "¿Estás seguro?",
        "Estas seguro de restaurar la transaccion con el id " + item.id
      );
      if (result.value) {
        this.isLoading = true;
        OperationService.restore(item)
          .then(() => {
            this.isLoading = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Restaurado`,
                text: `Registro restaurado`,
                icon: "InfoIcon",
                variant: "info",
              },
            });
            this.$refs.fulltable.refresh();
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Restaurar`,
                text: `Error al tratar de restaurar este elemento`,
                icon: "AlertOctagonIcon",
                variant: "danger",
              },
            });
          });
      }
    },

    /**
     *
     */
    async onExtraCharge(item) {
      this.$modal.show(
        ExtraCharge,
        {
          item: item,
          onClose: {
            handler: () => { },
          },
          onSave: {
            handler: () => {
              this.$refs.fulltable.refresh();
            },
          },
        },
        {
          styles: "margin-left: 10%",
          adaptive: true,
          width: "70%",
          height: "auto",
        }
      );
    },


    /**
     *
     */
    onPay() {
      if (this.isAdmin) {
        this.$modal.show(
          PayComponent,
          {
            item: this.operations.filter((d) => {
              return (
                d.status_payment === "PENDIENTE" &&
                d.user_id === this.customer.id
              );
            }),
            date_from: this.date_from,
            date_to: this.date_to,
            customer: this.customer,
            onClose: {
              handler: () => { },
            },
            onSave: {
              handler: () => {
                this.$refs.fulltable.refresh();
                this.operations = [];
              },
            },
          },
          {
            adaptive: true,
            width: "70%",
            height: "auto",
          }
        );
      }
    },

    
    /**
     *
     */
     onPayMercadoPago() {
      const transaction_extra_charges = this.operations.map((d) => d.id);
      this.$swal.queue([
        {
          title: "Pagar operaciones",
          confirmButtonText: "Pagar",
          text:
            "¿Estás seguro de que quieres pagar la operacion(es)" +
            transaction_extra_charges +
            "?",
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return MercadoPagoService.storeCharge({ transaction_extra_charges })
              .then((response) => {
                const { data } = response.data;
                this.$router.push("/dashboard/operations/pay/" + data.code);
              })
              .catch((error) => {
                console.error(error);
                this.$swal.insertQueueStep({
                  icon: "error",
                  title: "Error al intentar generar el pago",
                });
              });
          },
        },
      ]);
    },





  },
  computed: {
    filterActive() {
      return {
        "btn-warning": this.customer_id || this.date_from || this.date_to,
        "btn-primary": !(this.customer_id || this.date_from || this.date_to),
      };
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
