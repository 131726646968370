<template>
  <b-card>
    <b-tabs>

      <b-tab title="ACTIVOS" active>
        <loading :active.sync="isLoading" :full-poge="false"></loading>
        <b-card-title class="card-title mx-1" title-tag="div">
          <b-row no-gutters>
            <b-col sm="12" md="9" class="display: flex">

              <router-link
              class="btn btn-sm btn-primary mr-1 mb-1"
                to="/dashboard/operations/add"
                v-if="
                  isAdmin ||
                  (isCustomer && user.type === 'post-pago') ||
                  (isCustomer && user.keopsUser && user.type === 'pre-pago')
                "
                title="Agregar una nueva operación"
              >
                <feather-icon size="15" icon="PlusSquareIcon" class="mr-1" />
                <span style="font-size: 15px">Crear Envío</span>
              </router-link>

              <button
              class="btn btn-sm btn-primary mr-1 mb-1"
                @click="onFilter"
                title="Filtrar operaciones"
              >
                <feather-icon size="15" icon="FilterIcon" class="mr-1" />
                <span style="font-size: 15px">Filtrar</span>
              </button>

              <button
                class="btn btn-sm button-tertiary mr-1 mb-1"
                @click="onXml"
                title="Generar un reporte de operaciones"
              >
                <feather-icon size="15" icon="DownloadIcon" class="mr-50" />
                <span style="font-size: 15px">Descargar XLS</span>
              </button>
              <!-- TODO: boton para imprimir las etiquetas -->
              <button
                class="btn btn-sm button-tertiary mr-1 mb-1"
                @click="onLabel"
                v-if="operationsLabel.length > 0"
              >
                <feather-icon size="15" icon="TagIcon" class="mr-50" />
                <!-- <span style="font-size: 15px">Generar Etiqueta</span> -->
                <span style="font-size: 15px">Impresion masiva</span>
              </button>
              <button
                class="btn btn-sm btn-primary mr-1 mb-1"
                @click="onPay"
                v-if="operations.length > 0 && isAdminOrStaffOrReseller && customer && client.type === 'post-pago' && operations['0']['type'] != 'pre-pago'"
                title="Crear un balance"
              >
                <feather-icon size="15" icon="DollarSignIcon" class="mr-50" />
                <span style="font-size: 15px">Crear Balance</span>
              </button>

              <button
                v-b-tooltip.hover
                title="Pagar operaciones"
                class="btn btn-sm btn-primary mr-1 mb-1"
                @click="onPayMercadoPago"
                v-if="operations.length > 0 && isCustomer && user.type === 'pre-pago'"
              >
                <feather-icon size="15" icon="CreditCardIcon" class="mr-50" />
                <span style="font-size: 15px" v-if="operations.length > 1">
                  Pagar ({{ operations.length }})
                </span>
                <span style="font-size: 15px" v-else> Pagar Operación </span>
              </button>
              
              <button
                class="btn btn-sm button-tertiary mr-1 mb-1"
                title="Importar ordenes de Excel"
                @click="importOrdersFromExcel"
                v-if="isCustomer || isAdmin"
              >
                <feather-icon size="15" icon="FileTextIcon" class="mr-50" />
                <span style="font-size: 15px"> Carga Masiva </span>
              </button>
            </b-col>
            <b-col sm="12" md="3">
              <filter-bar-component
                ref="search"
                v-on:search="onSearch(null, true)"
              ></filter-bar-component>
            </b-col>
          </b-row>
        </b-card-title>

        <b-card-body class="card-body py-2 px-0">
          <full-table-component
            ref="fulltable"
            :fields="fields"
            :perPage="100"
            @selectTo="selectTo"
            @onLoadSuccess="onLoadSuccess"
            url="/transactions"
          >
            <template slot="buttons-slot" slot-scope="props">
              <menu-component
                :item="props.rowData"
                :response="response"
                @onView="onView"
                @onPDF="onPDF"
                @onDelete="onDelete"
                @onCancel="onCancel"
                @onPayOneMercadoPago="onPayOneMercadoPago"
                @onChangeState="onChangeState"
              />
            </template>
          </full-table-component>
        </b-card-body>
      </b-tab>

      <b-tab
        title="HISTORICO"
        @click="onOpenHistory()"
        v-if="isAdminOrStaffOrReseller"
      >
        <OperationHistory v-if="openHistory"></OperationHistory>
      </b-tab>

      <b-tab
        title="CARGOS EXTRAS"
        @click="onOpenExtraCharge()"
        v-if="
              isAdmin ||
              (isCustomer && user.type === 'post-pago') ||
              (isCustomer && user.keopsUser && user.type === 'pre-pago')
            "
      >
        <OperationExtraCharge v-if="OpenExtraCharge"></OperationExtraCharge>
      </b-tab>

    </b-tabs>
  </b-card>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// import * as _ from "lodash";
import customers from "./tables/customer";
import admins from "./tables/admin";

import FilterBarComponent from "@/layouts/components/FilterBarComponent";
import FullTableComponent from "@/layouts/components/FullTableComponent";

import OperationService from "@/services/OperationService";
import AlertService from "@/services/AlertService";
import UserService from "@/services/UserService";
import MercadoPagoService from "@/services/MercadoPagoService";

import OperationView from "./OperationView";
import PayComponent from "./PayComponent";
import FilterComponent from "./components/FilterComponent";
import MenuComponent from "./components/MenuComponent";

import OperationHistory from "./OperationHistory";
import OperationChargeVue from "./OperationCharge";
import moment from "moment";
import ImportOperationFromExcel from "./ImportOperationFromExcel";
import Operationservice from "@/services/OperationService";

// NUEVO CARGO EXTRA
import ExtraCharge from "./ExtraCharge";

import OperationExtraCharge from "./OperationExtraCharge";
// Modal para imprision masiva de las etiquetas
import MassivePrint from "./components/MassivePrint.vue";



export default {
  name: "OperationAll",
  components: {
    OperationHistory,
    OperationExtraCharge,
    // FilterComponent,
    FilterBarComponent,
    FullTableComponent,
    MenuComponent,
    // MassivePrint
  },
  mounted() {
    // validar informacion del usuario
    this.user = this.$store.state.user;
    if (this.user.type === "pre-pago" && this.user.in_progress_keops) {
      this.$swal({
        type: "warning",
        title: "Usuario Incompleto. ",
        text: "Aun no hemos validado la información de facturación de tu cuenta. Este proceso puede demorar de 1 a 5 minutos. Te enviaremos un email una vez que hayamos completado la validación.",
      });
    } else if (this.user.type === "pre-pago" && this.user.advance <= 95) {
      const text = "Tu perfil debe estar al 100% para poder realizar envios";
      this.$swal({
        type: "warning",
        title: "Usuario Incompleto. ",
        text: text,
        showCancelButton: true,
        confirmButtonText: "Editar mi perfil",
      }).then(({ value }) => {
        if (value) {
          this.$router.push("/dashboard/profile");
        }
      });
    }

    this.$store.subscribe((mutation, state) => {
      this.customer = state.customer;
      this.operations = state.operations;
    });

    if (this.$route.query.open) {
      this.onView({
        id: this.$route.query.open,
      });
    }
  },
  data() {
    const fields = UserService.isAdminOrStaffOrReseller()
      ? admins.fields(
          () => {
            /*
						if (_.isEmpty(this.customer)) {
							return true;
						}
						return value.status_payment !== "PENDIENTE";
						*/
            return false;
          },
          (value) => {
            return this.$options.filters.currency(value);
          }
        )
      : customers.fields((value) => {
          return this.$options.filters.currency(value);
        });
    const client = UserService.user();
    return {
      client: client,
      isLoading: false,
      fullPage: true,
      customer: null,
      date_from: null,
      date_to: null,
      fields: fields,
      operations: [],
      operationsLabel: [],
      isAdmin: UserService.isAdminOrStaff(),
      isReseller: UserService.isReseller(),
      isAdminOrStaffOrReseller: UserService.isAdminOrStaffOrReseller(),
      isCustomer: UserService.isCustomer(),
      openHistory: false,
      OpenExtraCharge: false,
      user: this.$store.state.user,
      mercadoPago: null,
      resource: null,
    };
  },
  methods: {
    /**
     *
     */
    onLoadSuccess(response) {
      this.response = response;
    },
    /**
     *
     */
    selectTo(items) {
      if (this.customer) {
        this.operations = items.filter((d) => {
          return d.user_id === this.customer.id;
        });
        console.log("A", this.operations);
        this.$store.commit("change", this.operations);
      } else {
        this.operations = items;
        console.log("B", this.operations);
      }

      this.operationsLabel = items.map((d) => d.id);
    },

    /**
     *
     */
    onSearch(event, clear) {
      const search = this.$refs.search ? this.$refs.search.filterText : "";

      const _from = this.date_from ? moment(this.date_from) : null;
      const _to = this.date_to ? moment(this.date_to) : null;

      this.$refs.fulltable.search(
        search,
        {
          customer: this.customer ? this.customer.id : null,
          from: this.date_from ? _from.format("YYYY-MM-DD") : null,
          to: this.date_to ? _to.format("YYYY-MM-DD") : null,
          type: this.type,
          status: this.status,
          retirement: this.retirement,
          collection: this.collection,
          status_payment: this.status_payment,
          type_shipping: this.type_shipping,
        },
        clear
      );
    },

    /**
     *
     */
    async onDelete(item) {
      const result = await AlertService.delete(
        "¿Estás seguro?",
        "Estas seguro de eliminar la transaccion con el id " + item.id
      );
      if (result.value) {
        this.isLoading = true;
        OperationService.destroy(item)
          .then(() => {
            this.isLoading = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Eliminar`,
                text: `Registro eliminado`,
                icon: "InfoIcon",
                variant: "info",
              },
            });
            this.$refs.fulltable.refresh();
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Eliminar`,
                text: `Error al tratar de eliminar este elemento`,
                icon: "AlertOctagonIcon",
                variant: "danger",
              },
            });
          });
      }
    },

    /**
     *
     */
    async onXml() {
      this.isLoading = true;
      const search = this.$refs.search ? this.$refs.search.filterText : "";
      const _from = this.date_from ? moment(this.date_from) : null;
      const _to = this.date_to ? moment(this.date_to) : null;
      OperationService.export({
        search: search,
        customer: this.customer ? this.customer.id : null,
        from: this.date_from ? _from.format("YYYY-MM-DD") : null,
        to: this.date_to ? _to.format("YYYY-MM-DD") : null,
        type: this.type,
        status: this.status,
        retirement: this.retirement,
        collection: this.collection,
        status_payment: this.status_payment,
        type_shipping: this.type_shipping,
      })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/octet-stream",
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          const fileName = "operaciones.xls";
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },

    /**
     *TODO: El codigo que tengo que copiar para mi nuevo modal
     */
    onFilter() {
      this.$modal.show(
        FilterComponent,
        {
          customer: this.customer,
          from: this.date_from,
          to: this.date_to,
          type: this.type,
          status: this.status,
          retirement: this.retirement,
          collection: this.collection,
          status_payment: this.status_payment,
          type_shipping: this.type_shipping,
          onClose: {
            handler: () => {},
          },
          onFilter: {
            handler: (item) => {
              this.customer = item.customer;
              this.$store.commit("changeCustomer", this.customer);
              this.date_from = item.from;
              this.date_to = item.to;
              this.type = item.type;
              this.status = item.status;
              this.retirement = item.retirement;
              this.collection = item.collection;
              this.status_payment = item.status_payment;
              this.type_shipping = item.type_shipping;

              if (item.clear) {
                this.$refs.fulltable.clear();
              } else {
                this.onSearch(null, true);
              }
            },
          },
        },
        {
          styles: "margin-left: 10%",
          adaptive: true,
          width: "50%",
          height: "80%",
        }
      );
    },

    /**
     *
     */
    onPay() {
      if (this.isAdmin) {
        this.$modal.show(
          PayComponent,
          {
            items: this.operations.filter((d) => {
              return (
                d.status_payment === "PENDIENTE" &&
                d.user_id === this.customer.id
              );
            }),
            date_from: this.date_from,
            date_to: this.date_to,
            customer: this.customer,
            onClose: {
              handler: () => {},
            },
            onSave: {
              handler: () => {
                this.$refs.fulltable.refresh();
                this.operations = [];
              },
            },
          },
          {
            adaptive: true,
            width: "70%",
            height: "auto",
          }
        );
      }
    },

    /**
     *
     */
    async onPDF(item) {
      const Http = new XMLHttpRequest();
      const url = item.pdf;
      Http.open("GET", url);
      Http.send();

      Http.onreadystatechange = () => {
        if (Http.responseText && Http.readyState === 4) {
          let response = Http.responseText;
          if (response === "You must pay to get the label") {
            AlertService.error(
              "Te falta un pasito",
              "Para poder ver y descargar la etiqueta, primero debes realizar el pago de la misma"
            );
          } else {
            const win = window.open(url, "_blank");
            win.focus();
          }
        }
      };
    },

    /**
     *
     */
    async onView(item) {
      this.$modal.show(
        OperationView,
        {
          item,
        },
        {
          styles: "margin-left: 10%",
          adaptive: true,
          width: "70%",
          height: "auto",
        }
      );
    },

    /**
     *
     */
    async onCharge(item) {
      this.$modal.show(
        OperationChargeVue,
        {
          item,
          onClose: {
            handler: () => {},
          },
          onSave: {
            handler: () => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Información`,
                  text: `Operacion Exitosa`,
                  icon: "InfoIcon",
                  variant: "info",
                },
              });
              this.$refs.fulltable.refresh();
            },
          },
        },
        {
          adaptive: true,
          width: "50%",
          height: "auto",
        }
      );
    },


    /**
     *
     */
     onOpenExtraCharge() {
      this.OpenExtraCharge = true;
    },

    /**
     *
     */
     onOpenHistory() {
      this.openHistory = true;
    },

    /**
     *
     */
    onPayOneMercadoPago(item) {
      this.$swal.queue([
        {
          title: "Pagar operaciones",
          confirmButtonText: "Pagar",
          text: "Estás seguro de querer pagar esta operación " + item.id,
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return MercadoPagoService.store({ transactions: [item.id] })
              .then((response) => {
                const { data } = response.data;
                this.$router.push("/dashboard/operations/pay/" + data.code);
              })
              .catch((error) => {
                console.error(error);
                this.$swal.insertQueueStep({
                  icon: "error",
                  title: "Error al intentar generar el pago",
                });
              });
          },
        },
      ]);
    },

    /**
     *
     */
    onChangeState(item) {
      this.$swal.queue([
        {
          title: "Marcar como pagado",
          confirmButtonText: "Confirmar",
          cancleButtonText: "Cancelar",
          text:
            "¿Estás seguro de querer cambiar el estado de la operación: " +
            item.id +
            " ?",
          showLoaderOnConfirm: true,
          showCancelButton: true,
          customClass: AlertService.customClass,
          reverseButtons: true,
          confirmButtonColor: AlertService.confirmButtonColor,
          cancelButtonColor: AlertService.cancelButtonColor,
          preConfirm: () => {
            return OperationService.changeState(item, item.id)
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Exito`,
                    text: `Se cambio el estado de la operación a pagada`,
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
                this.$refs.fulltable.refresh();
              })
              .catch((error) => {
                console.error(error);
                this.$swal.insertQueueStep({
                  icon: "error",
                  title: "Error al intentar generar el pago",
                });
              });
          },
        },
      ]);
    },

    /**
     *
     */
    onPayMercadoPago() {
      const transactions = this.operations.map((d) => d.id);
      this.isLoading = true;
      Operationservice.transactionsForPay(transactions)
        .then((response) => {
          this.isLoading = false;
          const { data } = response;
          const _transactions = data.map((d) => d.id);
          this.$swal.queue([
            {
              title: "Pagar operaciones",
              confirmButtonText: "Pagar",
              text:
                "¿Estás seguro de que quieres pagar la operacion(es)" +
                _transactions +
                "?",
              showLoaderOnConfirm: true,
              preConfirm: () => {
                return MercadoPagoService.store({ transactions: _transactions })
                  .then((response) => {
                    const { data } = response.data;
                    this.$router.push("/dashboard/operations/pay/" + data.code);
                  })
                  .catch((error) => {
                    console.error(error);
                    this.$swal.insertQueueStep({
                      icon: "error",
                      title: "Error al intentar generar el pago",
                    });
                  });
              },
            },
          ]);
        })
        .catch((error) => {
          this.isLoading = false;
          console.error(error);
        });
    },

    /**
     *
     */
    async importOrdersFromExcel() {
      this.$modal.show(
        ImportOperationFromExcel,
        {
          onClose: {
            handler: () => {},
          },
          onSave: {
            handler: () => {
              this.$refs.fulltable.refresh();
            },
          },
        },
        {
          adaptive: true,
          width: "50%",
          height: "auto",
        }
      );
    },

    /**
     *
     */
    async onCancel(item) {
      const result = await AlertService.questionText(
        `¿ Estás seguro de cancelar la transacción ?`,
        `Por favor escribe el motivo por el cual quieres cancelar la transacción con el id  ${item.id} `,
        {
          inputValidator: (value) => {
            if (value === "") {
              return "Es necesario que escribas el motivo.";
            }
          },
        }
      );
      if (result.value) {
        this.isLoading = true;
        try {
          await OperationService.cancel(item.id, {
            reason: result.value,
          });
          this.isLoading = false;
          this.$refs.fulltable.refresh();
        } catch (error) {
          console.error(error);
          this.isLoading = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Eliminar`,
              text: `Error al tratar de cancelar la transacción`,
              icon: "AlertOctagonIcon",
              variant: "danger",
            },
          });
        }
      }
    },

    /**
     *
     */
    onCanbeCanceled(item) {
      console.log(item);
      return () => {
        return true;
      };
    },

    /**
     *
     */
    onLabel() {
      this.$modal.show(
        MassivePrint,
        {
          onPrint: {
            handler: (totalTickets) => {
              console.log('no como vidrio');
              this.onPrint(totalTickets);
            },
          },
          onClose: {
            handler: () => {},
          }
        },
        {
          styles: "margin-left: 10%",
          adaptive: true,
          width: "50%",
          height: "50%",
        }
      );
    },
    
    onPrint(totalTickets) {
      console.log('purrete');
      const win = window.open(
        process.env.VUE_APP_API +
          "/v1/operation?transactions[]=" +
          this.operationsLabel.join("&transactions[]=") +
          "&total_tickets=" + totalTickets,
        "_blank"
      );
      win.focus();
    },

    /**
     *
     */
    async onExtraCharge(item) {
      this.$modal.show(
        ExtraCharge,
        {
          item: item,
          onClose: {
            handler: () => {},
          },
          onSave: {
            handler: () => {
              this.$refs.fulltable.refresh();
            },
          },
        },
        {
          styles: "margin-left: 10%",
          adaptive: true,
          width: "70%",
          height: "auto",
        }
      );
    },



  },
  computed: {
    filterActive() {
      return {
        "btn-warning": this.customer_id || this.date_from || this.date_to,
        "btn-primary": !(this.customer_id || this.date_from || this.date_to),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.vuetable-body {
  font-size: 11px !important;
}
</style>
