<template>
  <div class="list-group pb-2">
    <div v-for="item in statuses" v-bind:key="item.id">
      <div class="list-group-item flex-column align-items-start p-2 mb-2">
        <div class="d-flex w-100 justify-content-between">
          <p class="mb-1">
            <b>{{item.title}}</b>
          </p>
          <small>{{ toDateFecha(item.fecha)}} {{item.hora}}</small>
        </div>
        <p class="mb-1">
          <small>{{item.subtitle}}</small>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "OperationState",
  props: {
    statuses: Array
  },
  methods: {
    toDateFecha(date) {
      return moment(date).format("DD-MM-YYYY");
    }
  },
  computed: {}
};
</script>
<style lang="scss" scoped>
</style>
