<template>
  <div class="m-3">
    <div class="card-modal pt-0">
      <loading :active.sync="isLoading" :is-full-page="false"></loading>
      <b-tabs v-if="operation != null">
        <b-tab title="DATOS DE LA OPERACION" active>
          <div class="card p-2">



            <div class="card-body p-3">
              <h2 class="title">DATOS DEL TIENDA</h2>
              <RowComponent :title="'DNI :'" :content="operation.user_extraCharge.dni" />
              <RowComponent :title="'EMAIL :'" :content="operation.user_extraCharge.email" />
              <RowComponent :title="'MARCA :'" :content="operation.user_extraCharge.email" />
              <RowComponent
                :title="'NOMBRE APELLIDO :'"
                :content="operation.user_extraCharge.name + ' ' + operation.user_extraCharge.last_name"
              />
              <RowComponent
                :title="'TELEFONO :'"
                :content="operation.user_extraCharge.phone"
              />
              <div>
                <p>
                  <b>CREADO : </b>
                  <span>{{ operation.extraCharge.created_at | date_format }}</span>
                </p>
              </div>
              <div>
                <p>
                  <b>ACTUALIZADO : </b>
                  <span>{{ operation.extraCharge.updated_at | date_format }}</span>
                </p>
              </div>
            </div>
          </div>




            <div
              class="card mx-8"
              v-if="operation.extraCharge != 0"
            >

              <div class="card-body p-3">
                <h2 class="title">CARGO EXTRA</h2>
                <div
                  class="table-responsive"
                  style="max-height: 200px; overflow: auto; overflow-y: auto"
                >
                  <table class="table">
                    <caption>
                      Lista de cargo extra
                    </caption>
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th>TITULO</th>
                        <th>DETALLE</th>
                        <th>MONTO</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">{{ operation.extraCharge.id }}</th>
                        <td>{{ operation.extraCharge.title }}</td>
                        <td>{{ operation.extraCharge.body }}</td>
                        <td>{{ operation.extraCharge.price | currency }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>











        </b-tab>




      </b-tabs>
    </div>

    <footer class>
      <div class="row">
        <div class="col-12">
          <b-button size="sm"
            class="mr-3 mt-3 float-right"
            variant="outline-primary"
            @click="onClose"
            >Cerrar</b-button
          >

        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import OperationService from "@/services/OperationService";
import AlertService from "@/services/AlertService";
import UtilService from "@/services/UtilService";
import UserService from "@/services/UserService";

import _ from "vue-lodash";

import "vue-json-pretty/lib/styles.css";

export default {
  name: "OperationView",
  components: {
  },
  props: {
    item: Object,
  },
  data() {
    return {
      operation: null,
      isLoading: false,
      promise: null,
      customer: null,
      isAdmin: UserService.isAdminOrStaff(),
      isAdminOrStaffOrReseller: UserService.isAdminOrStaffOrReseller(),
    };
  },
  mounted() {
    this.show();
  },
  methods: {
    copy(item) {
      this.$clipboard(JSON.stringify(item, null, 4));
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: `Copiado`,
          text: `Payload`,
          icon: "InfoIcon",
          variant: "info",
        },
      });
    },

    getCollection(item) {
      return UtilService.serviceCollection(item);
    },
    getRetirement(item) {
      return UtilService.serviceRetirement(item);
    },
    async onRevoke() {
      const result = await AlertService.question(
        "¿Estás seguro?",
        "¿Esta seguro de querer anular ?"
      );
      if (result.value) {
        this.isLoading = true;
        OperationService.payBack({
          ids: [this.item.id],
        })
          .then(() => {
            this.isLoading = false;
            this.operation.status_payment = "PENDIENTE";
            AlertService.info();
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Esto de pago`,
                text: `Error al tratar de marcar como pendiente`,
                icon: "AlertOctagonIcon",
                variant: "danger",
              },
            });
          });
      }
    },


    onClose() {
      this.$emit("close");
    },


    async onPayBack() {
      const result = await AlertService.question(
        "¿Estás seguro?",
        "¿Esta seguro de querer marcar como pendiente "
      );
      if (result.value) {
        this.isLoading = true;
        OperationService.payBack({
          ids: [this.item.id],
        })
          .then(() => {
            this.isLoading = false;
            this.operation.status_payment = "PENDIENTE";
            AlertService.info();
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Esto de pago`,
                text: `Error al tratar de marcar como pendiente`,
                icon: "AlertOctagonIcon",
                variant: "danger",
              },
            });
          });
      }
    },

    show() {
      this.isLoading = true;
      OperationService.show(this.item.id)
        .then((response) => {
          const { data } = response;
          this.operation = data.data;
          this.isLoading = false;
        })
        .catch((thrown) => {
          this.isLoading = false;
          console.error(thrown);
        });
    },



  },
  computed: {
    orderedStatuses: function () {
      return _.uniqBy(
        _.orderBy(this.operation.statuses, ["pivot.fecha", "pivot.hora"]),
        "name"
      );
    },
    payload: function () {
      const payload = this.operation.payload;
      return payload.reverse();
    },
  },









};
</script>

<style lang="scss" scoped>
.card-modal {
  height: 450px;
  display: block;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.api {
  z-index: 1000000;
  position: absolute;
  right: 15px;
  top: 2px;
  color: white;
  margin: 3px;
  padding: 5px;
  border-radius: 4px;
  font-weight: bold;
}

.unigis {
  background: #f68d1e;
}

.deonics {
  background: #f68d1e;
}
</style>
