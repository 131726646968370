<template>
  <b-dropdown
    class="p-0"
    variant="link"
    toggle-class="text-decoration-none"
    no-caret
  >
    <template v-slot:button-content>
      <feather-icon size="20" icon="ListIcon" />
    </template>

    <b-dropdown-item @click="onView(item)">
      <feather-icon size="20" icon="SearchIcon" />
      Ver detalles
    </b-dropdown-item>
    <b-dropdown-item @click="onRestore(item)" v-if="isOnlyAdmin && canRestore">
      <feather-icon size="20" icon="RepeatIcon" />
      Restaurar
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import UserService from "@/services/UserService";
import moment from "moment";

export default {
  name: "MenuHistory",
  props: ["item"],
  data() {
    return {
      isAdmin: UserService.isAdminOrStaff(),
      isOnlyAdmin: UserService.isAdmin(),
      isCustomer: UserService.isCustomer(),
      user: this.$store.state.user,
    };
  },
  computed: {
    /**
     *
     */
    canRestore() {
      let itemDay = moment(this.item.deleted_at);
      let itemNow = moment(new Date());
      let totalDay = itemNow.diff(itemDay, "days");
      return totalDay <= 60;
    }
  },
  methods: {
    /**
     *
     */
    onView() {
      this.$emit("onView", this.item);
    },
    
    /**
     *
     */
    onRestore() {
      this.$emit("onRestore", this.item);
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  color: #6e6b7b !important;
}

li:hover a {
  color: #f68d1e !important;
}
</style>
