
<template>
  <div class="m-3">
    <header class="mx-4">
      <h3 class="title">Agrega cargo</h3>
    </header>

    <div class="card-body pt-0">
      <b-form-group label="Cargos">
        <multiselect
          name="service"
          data-vv-as="Cargos"
          class="mb-3"
          text="name"
          v-model="transaction.charges"
          :multiple="true"
          :state="validateState('service')"
          :options="services"
          label="name"
          track-by="name"
        >
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title">{{ props.option.name }}</span>
              <!-- <span class="option__small">{{ props.option.desc }}</span> -->
            </div>
          </template>
        </multiselect>
        <b-form-invalid-feedback>
          <ul>
            <li v-bind:key="error.id" v-for="error in veeErrors.collect('service')">{{ error }}</li>
          </ul>
        </b-form-invalid-feedback>
      </b-form-group>
      <div class="row">
        <div class="col-12">
          <b-button size="sm" class="mr-1 float-right" variant="success" @click="submit">Guardar</b-button>
          <b-button size="sm" class="mr-1 float-right" variant="danger" @click="close">Cerrar</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceService from "@/services/ServiceService";
// import OperationForm from "./OperationForm";

export default {
  name: "OperationEdit",
  props: {
    item: Object,
    onClose: Object,
    onSave: Object
  },
  components: {
    // OperationForm
  },
  data() {
    return {
      transaction: {
        charges: []
      },
      services: [],
      service: null
    };
  },
  methods: {
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    submit() {
      this.$validator.validate().then(valid => {
        if (valid) {
          ServiceService.add_charges({
            id: this.item.id,
            type: "transaction",
            charges: this.transaction.charges.map(o => o["id"])
          }).then(res => {
            this.transaction.charges = res.data.data;

            this.onSave.handler(this.transaction);
            this.$emit("close");
          });
        }
      });
      /*   this.onSave.handler(data); */
    },
    close() {
      /*      this.onClose.handler(); */
      this.$emit("close");
    },
    async loadAllServices() {
      ServiceService.index().then(res => {
        this.services = res.data.data;
      });
    },
    async loadAllCharges() {
      ServiceService.charges_transaction(this.item.id).then(res => {
        this.transaction.charges = res.data.data;
      });
    }
  },
  async mounted() {
    await this.loadAllServices();
    await this.loadAllCharges();
  }
};
</script>

<style lang="scss" scoped>
.card-body {
  height: 200px;
  display: block;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
