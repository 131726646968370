var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-dropdown',{staticClass:"p-0",attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{attrs:{"size":"20","icon":"ListIcon"}})]},proxy:true}])},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.onView(_vm.item)}}},[_c('feather-icon',{attrs:{"size":"20","icon":"SearchIcon"}}),_vm._v(" Ver detalles ")],1),(_vm.canBeDevolution)?_c('b-dropdown-item',[_c('router-link',{attrs:{"to":{
        path: 'operations/' + _vm.item.id + '/return-change',
        query: { type: 'devolution' },
      }}},[_c('feather-icon',{attrs:{"size":"20","icon":"CornerUpLeftIcon"}}),_vm._v(" Devolución ")],1)],1):_vm._e(),(_vm.canBeChange)?_c('b-dropdown-item',[_c('router-link',{attrs:{"to":{
        path: 'operations/' + _vm.item.id + '/return-change',
        query: { type: 'change' },
      }}},[_c('feather-icon',{attrs:{"size":"20","icon":"RefreshCcwIcon"}}),_vm._v(" Cambio ")],1)],1):_vm._e(),(_vm.canEdit)?_c('b-dropdown-item',[_c('router-link',{attrs:{"to":{
        path: 'operations/edit/' + _vm.item.id,
      }}},[_c('feather-icon',{attrs:{"size":"20","icon":"Edit3Icon"}}),_vm._v(" Editar ")],1)],1):_vm._e(),_c('b-dropdown-item',[_c('router-link',{attrs:{"to":{
        path: 'operations/duplicate/' + _vm.item.id,
      }}},[_c('feather-icon',{attrs:{"size":"20","icon":"CopyIcon"}}),_vm._v(" Duplicar ")],1)],1),(
      _vm.item.tracking_deonics &&
      ((_vm.user.type === 'pre-pago' && _vm.item.status_payment === 'PAGADO') ||
        _vm.user.type === 'post-pago')
    )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.onPDF(_vm.item)}}},[_c('feather-icon',{attrs:{"size":"20","icon":"TagIcon"}}),_vm._v(" Etiqueta ")],1):_vm._e(),(_vm.canbeCanceled)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.onCancel(_vm.item)}}},[_c('feather-icon',{attrs:{"size":"20","icon":"XIcon"}}),_vm._v(" Cancelar ")],1):_vm._e(),(_vm.isAdminOrStaff)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.onDelete(_vm.item)}}},[_c('feather-icon',{attrs:{"size":"20","icon":"TrashIcon"}}),_vm._v(" Eliminar ")],1):_vm._e(),(_vm.canPay)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.onPayOneMercadoPago(_vm.item)}}},[_c('feather-icon',{attrs:{"size":"20","icon":"CreditCardIcon"}}),_vm._v(" Pagar ")],1):_vm._e(),(
      (_vm.isOnlyAdmin && _vm.item.status === 'PENDIENTE POR PAGO') ||
      (_vm.item.status === 'Pendiente por Pago' && _vm.item.type === 'pre-pago')
    )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.onChangeState(_vm.item)}}},[_c('feather-icon',{attrs:{"size":"20","icon":"RepeatIcon"}}),_vm._v(" Marcar Pagado ")],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }