<template>
  <div class="m-3">
    <div class="card-modal pt-0">
      <loading :active.sync="isLoading" :is-full-page="false"></loading>
      <b-tabs v-if="operation != null">
        <b-tab title="DATOS DE LA OPERACION" active>
          <div class="card p-2">
            <span class="api deonics mt-1" v-if="operation.type_api === 1"
              >DEONICS</span
            >
            <span class="api unigis mt-1" v-if="operation.type_api === 2"
              >UNIGIS</span
            >
            <div class="card-body p-3">
              <h2 class="title">DATOS DEL TIENDA</h2>
              <RowComponent :title="'DNI:'" :content="operation.user.dni" />
              <RowComponent :title="'EMAIL:'" :content="operation.user.email" />
              <RowComponent :title="'MARCA:'" :content="operation.user.email" />
              <RowComponent
                :title="'NOMBRE APELLIDO:'"
                :content="operation.user.name + ' ' + operation.user.last_name"
              />
              <RowComponent
                :title="'TELEFONO:'"
                :content="operation.user.phone"
              />
              <div>
                <p>
                  <b>CREADO :</b>
                  <span>{{ operation.created_at | date_format }}</span>
                </p>
              </div>
              <div>
                <p>
                  <b>ACTUALIZADO :</b>
                  <span>{{ operation.updated_at | date_format }}</span>
                </p>
              </div>
            </div>
          </div>

          <div class="card p-2">
            <div class="card-body p-3">
              <h2 class="title">DATOS DEL PAQUETE</h2>

              <RowComponent
                :title="'ENVIO ASEGURADO :'"
                :content="operation.is_secured ? 'Si' : 'No'"
              />
              <RowComponent
                :title="'VALOR DECLARADO :'"
                :content="operation.declared_value | currency"
                v-if="operation.is_secured"
              />
              <RowComponent
                :title="'VALOR ASEGURADO :'"
                :content="
                  operation.declared_value > 15000
                    ? 15000
                    : operation.declared_value | currency
                "
                v-if="operation.is_secured"
              />

              <RowComponent
                :title="'PESO :'"
                :content="operation.weight + 'kg'"
              />
              <RowComponent
                :title="'DIMENSIONES:'"
                :content="operation.dimensions"
              />

              <RowComponent
                :title="'PRECIO ENVIO:'"
                :content="operation.shipping_amount | currency"
              />

              <RowComponent
                :title="'PRECIO SEGURO:'"
                :content="operation.declared_amount | currency"
                v-if="operation.is_secured"
              />

              <RowComponent
                :title="'PRECIO :'"
                :content="operation.amount | currency"
              />

              <RowComponent
                v-if="isAdmin && operation.price.percentage !== 0"
                :title="
                  operation.price.percentage > 0 ? 'DESCUENTO' : 'INCREMENTO'
                "
                :content="Math.abs(operation.price.percentage) + '%'"
              />

              <RowComponent
                :title="'STATUS :'"
                :content="operation.status"
                badge
              />

              <RowComponent
                :title="'RAZON DE CANCELACION:'"
                :content="operation.reason"
                v-if="operation.status == 'Envio Cancelado'"
              />

              <!-- operation.statuses -->
              <OperationState :statuses="operation.statuses" />

              <RowComponent
                :title="'TRACKING:'"
                :content="operation.tracking_deonics"
                v-if="operation.type_api === 1 && isAdminOrStaffOrReseller"
              />

              <RowComponent
                :title="'PAGADO:'"
                :content="operation.status_payment"
              />

              <RowComponent
                :title="'Codigo Seguimiento OCASA:'"
                :content="operation.codigoSeguimientoOCASA"
              />

              <RowComponent :title="'PRODUCTO:'" :content="operation.product" />
            </div>
          </div>

          <div class="card p-2">
            <div class="card-body p-3">
              <h2 class="title">DATOS DEL LA COLECTA</h2>
              <RowComponent
                :title="'TIPO :'"
                :content="getCollection(operation.collection)"
              />

              <RowComponent
                :title="'PROVINCIA:'"
                :content="operation.collection_addresses.province"
              />
              <RowComponent
                :title="'LOCALIDAD:'"
                :content="operation.collection_addresses.location"
              />
              <RowComponent
                :title="'CALLE:'"
                :content="operation.collection_addresses.street"
              />
              <RowComponent
                :title="'ALTURA:'"
                :content="operation.collection_addresses.height"
              />
              <RowComponent
                :title="'CODIGO POSTAL:'"
                :content="operation.collection_addresses.postal_code"
              />
              <RowComponent
                :title="'NOTA:'"
                :content="operation.collection_addresses.note"
                v-if="operation.collection_addresses.note"
              />
            </div>
          </div>

          <div class="card p-2">
            <div class="card-body p-3">
              <h2 class="title">DATOS DEL ENVIO</h2>
              <RowComponent
                :title="'TIPO :'"
                :content="getRetirement(operation.retirement)"
              />
              <RowComponent
                :title="'NOMBRE :'"
                :content="operation.recipient_name"
              />
              <RowComponent
                :title="'APELLIDO :'"
                :content="operation.recipient_last_name"
              />
              <RowComponent
                :title="'TELEFONO :'"
                :content="operation.recipient_phone"
              />
              <RowComponent
                :title="'EMAIL :'"
                :content="operation.recipient_email"
              />
              <RowComponent
                :title="'PROVINCIA:'"
                :content="operation.retirement_addresses.province"
              />
              <RowComponent
                :title="'LOCALIDAD:'"
                :content="operation.retirement_addresses.location"
              />
              <RowComponent
                :title="'CALLE:'"
                :content="operation.retirement_addresses.street"
              />
              <RowComponent
                :title="'ALTURA:'"
                :content="operation.retirement_addresses.height"
              />
              <RowComponent
                :title="'PISO:'"
                :content="operation.retirement_addresses.floor"
              />
              <RowComponent
                :title="'DEPARTAMENTO:'"
                :content="operation.retirement_addresses.departament"
              />

              <RowComponent
                :title="'CODIGO POSTAL:'"
                :content="operation.retirement_addresses.postal_code"
              />
              <RowComponent
                :title="'NOTA DE DIRECCION:'"
                :content="operation.retirement_addresses.note"
                v-if="operation.retirement_addresses.note"
              />

              <RowComponent
                :title="'NOTA GENERAL:'"
                :content="operation.notes"
                v-if="operation.notes"
              />
            </div>
          </div>

          <div class="card p-2" v-if="operation.charges.length != 0">
            <div class="card-body p-3">
              <h2 class="title">SERVICIOS</h2>

              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Servicio</th>
                      <th scope="col">Monto</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-bind:key="item.id" v-for="item in operation.charges">
                      <th scope="row">{{ item.id }}</th>
                      <td>{{ item.name }}</td>
                      <td>
                        <span v-if="item.percentage == 0">{{
                          item.amount
                        }}</span>
                        <span v-else>{{
                          (item.percentage / 100) * operation.shipping_amount
                        }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="card mx-8" v-if="operation.extraCharge != 0">
            <div class="card-body p-3">
              <h2 class="title">CARGO EXTRA</h2>
              <div
                class="table-responsive"
                style="max-height: 200px; overflow: auto; overflow-y: auto"
              >
                <table class="table">
                  <caption>
                    Lista de cargo extra
                  </caption>
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th>TITULO</th>
                      <th>DETALLE</th>
                      <th>MONTO</th>
                      <th>ACCION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">{{ operation.extraCharge.id }}</th>
                      <td>{{ operation.extraCharge.title }}</td>
                      <td>{{ operation.extraCharge.body }}</td>
                      <td>{{ operation.extraCharge.price | currency }}</td>
                      <td>
                        <b-button
                          size="sm"
                          variant="primary"
                          @click="onDeleteCharge"
                          >Cancelar</b-button
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab title="PAYLOAD UNIGIS" v-if="operation.payload && isAdmin">
          <b-card v-bind:key="index" v-for="(payload, index) in payload">
            <span
              style="
                cursor: pointer;
                height: 30px;
                margin-right: 5px;
                margin-bottom: 15px;
              "
              @click="copy(payload.payload)"
            >
              <i class="fa fa-files-o" aria-hidden="true"></i>
              <span v-if="payload.operation == 'add'"> Almacenar </span>
              <span v-if="payload.operation == 'cancel'"> Cancelar </span>
              <span v-if="payload.operation == 'update'"> Actualizar </span>
              ({{ payload.created_at }}).
            </span>
            <vue-json-pretty
              :deep="2"
              :data="payload.payload"
            ></vue-json-pretty>
          </b-card>
        </b-tab>
      </b-tabs>
    </div>

    <footer class>
      <div class="row">
        <div class="col-12">
          <b-button
            size="sm"
            class="mr-3 mt-3 float-right"
            variant="outline-primary"
            @click="onClose"
            >Cerrar</b-button
          >
          <b-button
            size="sm"
            class="mr-2 mt-3 float-right"
            variant="primary"
            @click="onRevoke"
            v-if="isAdmin && operation && operation.status === 'NEW'"
            >Anular</b-button
          >
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import OperationService from "@/services/OperationService";
import AlertService from "@/services/AlertService";
import UtilService from "@/services/UtilService";
import UserService from "@/services/UserService";

import OperationState from "./components/OperationState";
import _ from "vue-lodash";

import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";

export default {
  name: "OperationView",
  components: { VueJsonPretty, OperationState },
  props: {
    item: Object,
  },
  data() {
    return {
      operation: null,
      isLoading: false,
      promise: null,
      customer: null,
      isAdmin: UserService.isAdminOrStaff(),
      isAdminOrStaffOrReseller: UserService.isAdminOrStaffOrReseller(),
    };
  },
  mounted() {
    this.show();
  },
  methods: {
    copy(item) {
      this.$clipboard(JSON.stringify(item, null, 4));
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: `Copiado`,
          text: `Payload`,
          icon: "InfoIcon",
          variant: "info",
        },
      });
    },

    getCollection(item) {
      return UtilService.serviceCollection(item);
    },
    getRetirement(item) {
      return UtilService.serviceRetirement(item);
    },
    async onRevoke() {
      const result = await AlertService.question(
        "¿Estás seguro?",
        "¿Esta seguro de querer anular ?"
      );
      if (result.value) {
        this.isLoading = true;
        OperationService.payBack({
          ids: [this.item.id],
        })
          .then(() => {
            this.isLoading = false;
            this.operation.status_payment = "PENDIENTE";
            AlertService.info();
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Esto de pago`,
                text: `Error al tratar de marcar como pendiente`,
                icon: "AlertOctagonIcon",
                variant: "danger",
              },
            });
          });
      }
    },

    onClose() {
      this.$emit("close");
    },

    async onPayBack() {
      const result = await AlertService.question(
        "¿Estás seguro?",
        "¿Esta seguro de querer marcar como pendiente "
      );
      if (result.value) {
        this.isLoading = true;
        OperationService.payBack({
          ids: [this.item.id],
        })
          .then(() => {
            this.isLoading = false;
            this.operation.status_payment = "PENDIENTE";
            AlertService.info();
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Esto de pago`,
                text: `Error al tratar de marcar como pendiente`,
                icon: "AlertOctagonIcon",
                variant: "danger",
              },
            });
          });
      }
    },

    show() {
      this.isLoading = true;
      OperationService.show(this.item.id)
        .then((response) => {
          const { data } = response;
          this.operation = data.data;
          this.isLoading = false;
        })
        .catch((thrown) => {
          this.isLoading = false;
          console.error(thrown);
        });
    },

    async onDeleteCharge() {
      this.isLoading = true;
      const result = await AlertService.question(
        "¿Estás seguro?",
        "¿Esta seguro de querer cancelar el cargo extra?"
      );
      if (result.value) {
        OperationService.deleteCharge(this.operation.extraCharge.id)
          .then((response) => {
            const { data } = response;
            this.operation = data.data;
            this.isLoading = false;
            this.$emit("close");
          })
          .catch((thrown) => {
            this.isLoading = false;
            console.error(thrown);
          });
      }
    },
  },
  computed: {
    orderedStatuses: function () {
      return _.uniqBy(
        _.orderBy(this.operation.statuses, ["pivot.fecha", "pivot.hora"]),
        "name"
      );
    },
    payload: function () {
      const payload = this.operation.payload;
      return payload.reverse();
    },
  },
};
</script>

<style lang="scss" scoped>
.card-modal {
  height: 500px;
  display: block;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.api {
  z-index: 1000000;
  position: absolute;
  right: 15px;
  top: 2px;
  color: white;
  margin: 3px;
  padding: 5px;
  border-radius: 4px;
  font-weight: bold;
}

.unigis {
  background: #f68d1e;
}

.deonics {
  background: #f68d1e;
}
</style>
