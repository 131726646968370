<template>
    <div class="m-2" style="max-height: 50%">
      <div class="card-body card-modal pt-0">
        <h3 class="title ml-1">Opciones de impresion masiva</h3>
        <form class="p-1">
          <b-row>
            <b-col>
              <b-form-group label="Cantidad por pagina">
                <v-select
                  class="searchCustomer"
                  :options="options"
                  :reduce="(v) => v.code"
                  v-model="totalTickets"
                />
              </b-form-group>
            </b-col>

          </b-row>
  
          <div class="mt-1">
            <button type="button" class="btn btn-primary" @click="print">
              Imprimir
            </button>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  
  export default {
    name: "MassivePrint",
    components: {},
    data() {
      return {
        item: {
          customer: null,
          from: null,
          to: null,
        },
        options: [
            {code: 2, label: "Impresion masiva"},
            {code: 1, label: "Impresion masiva 1 por hoja"}
        ],
        totalTickets: 1
      };
    },
    methods: {
    onClear() {
        this.onCloe.handler();
    },
    print() {
        this.onPrint.handler(this.totalTickets);
      },
    },
    mounted() {
    },
    props: {
      onClose: Object,
      onPrint: Object
    },
  };
  </script>
  <style lang="scss" scoped>
  .card-modal {
    height: 250px;
    display: block;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  .elements {
    height: 550px;
    overflow-y: hidden;
  }
  
  .md-datepicker-dialog {
    z-index: 1040 !important;
  }
  </style>
  