<template>
  <b-dropdown
    class="p-0"
    variant="link"
    toggle-class="text-decoration-none"
    no-caret
  >
    <template v-slot:button-content>
      <feather-icon size="20" icon="ListIcon" />
    </template>

    <b-dropdown-item @click="onView(item)">
      <feather-icon size="20" icon="SearchIcon" />
      Ver detalles
    </b-dropdown-item>

    <b-dropdown-item v-if="canBeDevolution">
      <router-link
        :to="{
          path: 'operations/' + item.id + '/return-change',
          query: { type: 'devolution' },
        }"
      >
        <feather-icon size="20" icon="CornerUpLeftIcon" />
        Devolución
      </router-link>
    </b-dropdown-item>

    <b-dropdown-item v-if="canBeChange">
      <router-link
        :to="{
          path: 'operations/' + item.id + '/return-change',
          query: { type: 'change' },
        }"
      >
        <feather-icon size="20" icon="RefreshCcwIcon" />
        Cambio
      </router-link>
    </b-dropdown-item>

    <b-dropdown-item v-if="canEdit">
      <router-link
        :to="{
          path: 'operations/edit/' + item.id,
        }"
      >
        <feather-icon size="20" icon="Edit3Icon" />
        Editar
      </router-link>
    </b-dropdown-item>

    <b-dropdown-item>
      <router-link
        :to="{
          path: 'operations/duplicate/' + item.id,
        }"
      >
        <feather-icon size="20" icon="CopyIcon" />
        Duplicar
      </router-link>
    </b-dropdown-item>

    <b-dropdown-item
      @click="onPDF(item)"
      v-if="
        item.tracking_deonics &&
        ((user.type === 'pre-pago' && item.status_payment === 'PAGADO') ||
          user.type === 'post-pago')
      "
    >
      <feather-icon size="20" icon="TagIcon" />
      Etiqueta
    </b-dropdown-item>

    <b-dropdown-item @click="onCancel(item)" v-if="canbeCanceled">
      <feather-icon size="20" icon="XIcon" />
      Cancelar
    </b-dropdown-item>

    <b-dropdown-item @click="onDelete(item)" v-if="isAdminOrStaff">
      <feather-icon size="20" icon="TrashIcon" />
      Eliminar
    </b-dropdown-item>

    <b-dropdown-item @click="onPayOneMercadoPago(item)" v-if="canPay">
      <feather-icon size="20" icon="CreditCardIcon" />
      Pagar
    </b-dropdown-item>

    <b-dropdown-item
      @click="onChangeState(item)"
      v-if="
        (isOnlyAdmin && item.status === 'PENDIENTE POR PAGO') ||
        (item.status === 'Pendiente por Pago' && item.type === 'pre-pago')
      "
    >
      <feather-icon size="20" icon="RepeatIcon" />
      Marcar Pagado
    </b-dropdown-item>





  </b-dropdown>
</template>

<script>
import UserService from "@/services/UserService";

export default {
  props: ["item", "response"],
  data() {
    return {
      isAdmin: UserService.isAdminOrStaff(),
      isAdminOrStaff: UserService.isAdminOrStaff(),
      isOnlyAdmin: UserService.isAdmin(),
      isCustomer: UserService.isCustomer(),
      user: this.$store.state.user,
    };
  },
  computed: {
    /**
     *
     */
    canPay() {
      return (
        this.response.data.canPay &&
        this.item.status_payment === "PENDIENTE" &&
        this.isCustomer
      );
    },

    /**
     *
     */
    canEdit() {
      if (this.item.tracking_deonics) {
        if (this.item.status === "Envio Cancelado") {
          return false;
        }

        if (this.item.status === "En proceso de registro") {
          return false;
        }
        return true;
      }
      if (this.isCustomer && this.item.tracking_deonics) {
        const arr = [
          "Envio Creado con Éxito",
          "Retiro creado con éxito",
          "Devolucion en Punto creado con éxito",
        ];
        if (arr.includes(this.item.status)) {
          return true;
        }
        return false;
      }
      return false;
    },

    /**
     *
     */
    canBeChange() {
      if (this.item.status === "Envio Cancelado") {
        return false;
      }

      if (this.isCustomer && this.item.retirement == "point") {
        return false;
      }

      return (
        // this.isAdmin &&
        this.item.tracking_deonics &&
        this.item.type_shipping === "shipping" &&
        this.item.canChangedOrReturned
      );
    },

    /**
     *
     */
    canBeDevolution() {
      if (this.item.status === "Envio Cancelado") {
        return false;
      }
      return (
        // this.isAdmin &&
        this.item.tracking_deonics &&
        this.item.type_shipping === "shipping" &&
        this.item.canChangedOrReturned
      );
    },

    /**
     *
     */
    canbeCanceled() {
      
      if (this.item.status === "Envio Cancelado") {
        return false;
      }

      if (
        this.item.status === "Envio Creado con Éxito" &&
        this.user.type === "pre-pago"
      ) {
        return false;
      }

      if (!this.item.tracking_deonics) {
        return false;
      }

      const arr = ["Pendiente por Pago", "Envio Creado con Éxito"];

      return this.isAdminOrStaff || arr.includes(this.item.status);
    },
  },
  methods: {
    /**
     *
     */
    onView() {
      this.$emit("onView", this.item);
    },

    /**
     *
     */
    onPDF() {
      this.$emit("onPDF", this.item);
    },

    /**
     *
     */
    onDelete() {
      this.$emit("onDelete", this.item);
    },

    /**
     *
     */
    onCancel() {
      this.$emit("onCancel", this.item);
    },

    /**
     *
     */
    onPayOneMercadoPago() {
      this.$emit("onPayOneMercadoPago", this.item);
    },

    /**
     *
     */
    onChangeState() {
      this.$emit("onChangeState", this.item);
    },


  },
};
</script>

<style lang="scss" scoped>
a {
  color: #6e6b7b !important;
}

li:hover a {
  color: #f68d1e !important;
}
</style>
