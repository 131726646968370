
<template>
  <div class="m-3">
    <header class="mx-4">
      <h3 class="title">Facturar Operaciones</h3>
    </header>
    <div class="card-body pt-0">
      <div class="py-3">
        <label for="vat_condition" class="pt-0">Condición IVA</label>
        <select v-model="vat_condition" class="custom-select">
          <option value="Responsable Inscripto">Responsable Inscripto</option>
          <option value="Monotributista">Monotributista</option>
          <option value="Consumidor Final">Consumidor Final</option>
          <option value="Exento 0%">Exento 0%</option>
          <option value="No gravado">No gravado</option>
          <option value="Otro">Otro</option>
        </select>

        <label for="voucher_type" class="pt-4">Tipo de Comprobante</label>
        <select v-model="voucher_type" class="custom-select">
          <option value="Factura A">Factura A</option>
          <option value="Factura B">Factura B</option>
        </select>
      </div>

      <h4 class="title">Listado de operaciones</h4>
      <div class="table-responsive" style="height: 100px">
        <table class="table table-bordered table-sm text-center">
          <thead>
            <tr>
              <th>#</th>
              <th>Id</th>
              <th>Fecha</th>
              <th>Cliente</th>
              <th>Titulo</th>
              <th>Total</th>
              <th>Pago</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in elements" v-bind:key="item.id">
              <td>
                <input type="checkbox" v-model="item.select" />
              </td>
              <td>{{ item.id }}</td>
              <td>{{ toDateFecha(item.created_at) }}</td>
              <td>{{ item.brand}}</td>
              <td>{{ item.title}}</td>
              <td>{{ item.price | currency}}</td>
              <td>{{ item.status_payment }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <button class="btn btn-primary btn-sm float-right my-2" @click="save">
          Procesar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UserService from "@/services/UserService";
import AlertService from "@/services/AlertService";
import OperationService from "@/services/OperationService";

import moment from "moment";

export default {
  name: "PayComponent",
  props: {
    onClose: Object,
    onSave: Object,
    customer: Object,
    date_from: Object || Date,
    date_to: Object || Date,
    item: Array,
  },
  data() {
    const elements = this.item.map((d) => {
      d.select = true;
      return d;
    });
    const vat_condition = this.customer.vat_condition;
    const voucher_type = this.customer.voucher_type;
    return {
      vat_condition: vat_condition,
      voucher_type: voucher_type,
      invoice_number: null,
      elements,
      services: null,
      isAdmin: UserService.isAdminOrStaff(),
    };
  },
  methods: {
    async save() {

      const transactions = this.elements.filter((d) => d.select);
      const services = this.services.filter((d) => d.select);
      const result = await AlertService.question(
        "¿Estás seguro?",
        "¿Esta seguro de querer procesar?"
      );
      if (result.value) {
        this.isLoading = true;
        OperationService.payUp({
          extra_charge: true,
          customer_id: this.customer.id,
          vat_condition: this.vat_condition,
          voucher_type: this.voucher_type,
          invoice_number: this.invoice_number,
          transactions: transactions.map((d) => d.id),
          charges: services.map((d) => d.id),
        })
          .then(() => {
            this.$store.commit("change", []);
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Información`,
                text: `Operacion Exitosa`,
                icon: "InfoIcon",
                variant: "info",
              },
            });
            this.onSave.handler();
            this.$emit("close");
          })
          .catch((error) => {
            console.error(error);
            const response = error.response;
            if (response.status === 422) {
              const keys = Object.keys(response.data.errors);
              keys.forEach((key) => {
                response.data.errors[key].forEach((error) => {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Error`,
                      text: `${error}`,
                      icon: "AlertOctagonIcon",
                      variant: "danger",
                    },
                  });
                });
              });
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Error`,
                  text: `Error al tratar de procesar`,
                  icon: "AlertOctagonIcon",
                  variant: "danger",
                },
              });
            }
          });
      }
    },
    toDateFecha(date) {
      return moment(date).format("DD-MM-YYYY");
    },
  },
  mounted() {
    const date_from = this.date_from
      ? moment(this.date_from).format("YYYY-MM-DD")
      : null;
    const date_to = this.date_to
      ? moment(this.date_to).format("YYYY-MM-DD")
      : null;
    OperationService.collectionPending(
      this.customer.id,
      this.elements.map((d) => d.id),
      date_from,
      date_to
    )
      .then(({ data }) => {
        this.services = data.data;
        this.services = this.services.map((d) => {
          d.select = true;
          d.date_collection_from = moment(d.date_collection)
            .subtract(1, "days")
            .format("Y-M-D");
          d.transactions = d.transactions
            ? d.transactions.map((d) => d.id).join(", ")
            : [];
          return d;
        });
      })
      .catch((error) => {
        console.error(error);
      });
  },
};
</script>

<style lang="scss" scoped>
.card-body {
  display: block;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
