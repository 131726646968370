<template>
  <div ref="container">
    <loading :active.sync="isLoading" :full-poge="false"></loading>
    <b-card-title class="card-title mb-1">
      <b-row class="row">
        <b-col cols="4">
          <button
            class="btn btn-sm button-primary mx-1"
            @click="onFilter"
            :class="filterActive"
          >
            <feather-icon size="15" icon="FilterIcon" class="mr-1" />
            <span style="font-size: 15px">Filtrar</span>
          </button>

          <button
            class="btn btn-sm btn-primary ml-1"
            @click="onPay"
            v-if="operations.length !== 0"
          >
            <feather-icon size="15" icon="CreditCardIcon" class="mr-1" />
            <span style="font-size: 15px">Pagar</span>
          </button>
        </b-col>
      </b-row>
    </b-card-title>
    <b-card-body class="card-body py-2 px-0">
      <full-table-component
        ref="fulltable"
        :fields="fields"
        :perPage="100"
        @selectTo="selectTo"
        url="/transactions/history"
      >
        <template slot="buttons-slot" slot-scope="props">
          <menu-history-component 
          :item="props.rowData" 
          @onView="onView" 
          @onRestore="onRestore" 
          />
        </template>
      </full-table-component>
    </b-card-body>
  </div>
</template>
<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// import FilterBarComponent from "@/layouts/components/FilterBarComponent";
import FullTableComponent from "@/layouts/components/FullTableComponent";
// import ConfirmComponent from "@/layouts/components/ConfirmComponent";
// import Checkbox2Component from "@/layouts/components/Checkbox2Component";

import UserService from "@/services/UserService";
import UtilService from "@/services/UtilService";
import OperationService from "@/services/OperationService";
import AlertService from "@/services/AlertService";

import OperationView from "./OperationView";
import FilterComponent from "./components/FilterComponent";
import MenuHistoryComponent from './components/MenuHistoryComponent.vue'
import moment from "moment";

export default {
  name: "OperationHistory",
  components: {
    // FilterComponent,
    // FilterBarComponent,
    FullTableComponent,
    MenuHistoryComponent
    // ConfirmComponent,
    // Checkbox2Component
  },
  data() {
    const fields = [
      {
        name: "id",
        title: "Id",
        sortField: "id",
      },
      {
        name: "created_at",
        title: "Creado",
        sortField: "created_at",
        formatter: (value) => {
          return UtilService.date(value);
        },
      },
      {
        name: "customer",
        title: "Tienda",
        sortField: "customer",
      },
      {
        name: "recipient",
        title: "Destinatario",
        sortField: "recipient",
      },
      {
        name: "collection",
        title: "Servicio de colecta",
        sortField: "collection",
        formatter: (value) => {
          return UtilService.serviceCollection(value);
        },
      },
      {
        name: "retirement",
        title: "Servicio de colecta",
        sortField: "retirement",
        formatter: (value) => {
          return UtilService.serviceRetirement(value);
        },
      },
      {
        name: "status",
        title: "Estado",
        sortField: "status",
      },
      {
        name: "tracking_deonics",
        title: "Tracking",
        sortField: "tracking_deonics",
      },
      {
        name: "status_payment",
        title: "Pago",
        sortField: "status_payment",
      },
      {
        name: "buttons-slot",
        title: "Acciones",
        titleClass: "text-right",
        dataClass: "text-right align-middle",
      },
    ];
    return {
      isLoading: false,
      fullPage: true,
      customer: null,
      date_from: null,
      date_to: null,
      fields: fields,
      operations: [],
      isAdmin: UserService.isAdminOrStaff(),
    };
  },
  methods: {
    selectTo(item) {
      if (this.customer) {
        this.operations = item.filter((d) => {
          return d.user_id === this.customer.id;
        });
        this.$store.commit("change", this.operations);
      }
    },
    onSearch(event, clear) {
      const search = this.$refs.search ? this.$refs.search.filterText : "";
      const _from = this.date_from ? moment(this.date_from) : null;
      const _to = this.date_to ? moment(this.date_to) : null;
      this.$refs.fulltable.search(
        search,
        {
          customer: this.customer ? this.customer.id : null,
          from: this.date_from ? _from.format("YYYY-MM-DD") : null,
          to: this.date_to ? _to.format("YYYY-MM-DD") : null,
        },
        clear
      );
    },
    onFilter() {
      this.$modal.show(
        FilterComponent,
        {
          customer: this.customer,
          from: this.date_from,
          to: this.date_to,
          onClose: {
            handler: () => {},
          },
          onFilter: {
            handler: (item) => {
              this.customer = item.customer;
              this.$store.commit("changeCustomer", this.customer);
              this.date_from = item.from;
              this.date_to = item.to;
              if (item.clear) {
                this.$refs.fulltable.clear();
              } else {
                this.onSearch(null, true);
              }
            },
          },
        },
        {
          adaptive: true,
          width: "50%",
          height: "auto",
        }
      );
    },
    async onView(item) {
      this.$modal.show(
        OperationView,
        {
          item,
        },
        {
          adaptive: true,
          width: "70%",
          height: "auto",
        }
      );
    },
    async onRestore(item) {
      const result = await AlertService.delete(
        "¿Estás seguro?",
        "Estas seguro de restaurar la transaccion con el id " + item.id
      );
      if (result.value) {
        this.isLoading = true;
        OperationService.restore(item)
          .then(() => {
            this.isLoading = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Restaurado`,
                text: `Registro restaurado`,
                icon: "InfoIcon",
                variant: "info",
              },
            });
            this.$refs.fulltable.refresh();
          })
          .catch((error) => {
            console.error(error);
            this.isLoading = false;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Restaurar`,
                text: `Error al tratar de restaurar este elemento`,
                icon: "AlertOctagonIcon",
                variant: "danger",
              },
            });
          });
      }
    },
  },
  computed: {
    filterActive() {
      return {
        "btn-warning": this.customer_id || this.date_from || this.date_to,
        "btn-primary": !(this.customer_id || this.date_from || this.date_to),
      };
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
