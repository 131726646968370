
import UtilService from "@/services/UtilService";
// import * as _ from "lodash";

export default {
    fields: (isDisabled, formatCurrency) => {
        return [
            {
                name: "__checkbox2",
                isDisabled: isDisabled
            },
            {
                name: "id",
                title: "Id",
                sortField: "id"
            },
            {
                name: "created_at",
                title: "Creado",
                width: 110,
                sortField: "created_at",
                formatter: value => {
                    return UtilService.date(value);
                }
            },
            {
                name: "channel",
                title: "Canal",
                sortField: "channel"
            },
            {
                name: "type",
                title: "Tipo",
                width: 60,
                sortField: "type"
            },
            {
                name: "customer",
                title: "Tienda",
                sortField: "customer"
            },
            {
                name: "recipient",
                title: "Destinatario",
                sortField: "recipient"
            },
            {
                name: "collection",
                title: "Colecta",
                sortField: "collection",
                formatter: value => {
                    return UtilService.serviceCollection(value);
                }
            },
            {
                name: "retirement",
                title: "Entrega",
                sortField: "retirement",
                formatter: value => {
                    return UtilService.serviceRetirement(value);
                }
            },
            {
                name: "status",
                title: "Estado",
                width: 120,
                sortField: "status"
            },
            {
                name: "tracking_deonics",
                title: "Tracking",
                sortField: "tracking_deonics"
            },
            {
                name: "amount",
                title: "Precio",
                dataClass: "text-left",
                sortField: "amount",
                width: "6%",
                formatter: formatCurrency
            },
            {
                name: "status_payment",
                title: "Pago",
                sortField: "status_payment"
            },
            {
                name: "type_shipping",
                title: "Envío",
                dataClass: "text-center",
                sortField: "type_shipping",
                formatter: value => {
                    if (value === 'devolution') {
                        return 'Devolución';
                    }
                    if (value === 'change') {
                        return 'Cambio';
                    }
                    if (value === 'shipping') {
                        return 'Envío';
                    }
                    return '';
                }
            },
            {
                name: "buttons-slot",
                title: "Acciones",
                titleClass: "text-right",
                dataClass: "text-right align-middle",
                width: "5%"
            }
        ];
    }
}