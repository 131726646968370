<template>
  <div class="m-3">
    <div class="card-body card-modal pt-0">
      <h3 class="title">Cargo Extra</h3>
      <div class="row justify-content-center" v-if="charge">
        <div class="col-12">
          <validation-observer
            ref="editChargeForm"
          >
          <b-form @submit="onSubmit">

            <!-- Cliente -->
            <b-form-group label="Cliente">
              <v-select
                size="sm"
                :options="customers"
                label="fullText"
                v-model="charge.customer"
                class="style-chooser pb-1"
              />
            </b-form-group>



            <!-- Titulo -->
            <b-form-group label="Titulo">
              <validation-provider
                #default="{ errors }"
                name="title"
                rules="required|max:150"
              >
                <b-form-input
                  size="sm"
                  v-model="charge.title"
                  autocomplete="off"
                  placeholder="Titulo"
                  type="text"
                  name="title"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Detalle -->
            <b-form-group label="Detalle">
              <validation-provider
                #default="{ errors }"
                name="body"
                rules="required|max:150"
              >
                <b-form-textarea
                  size="sm"
                  v-model="charge.body"
                  autocomplete="off"
                  placeholder="Detalle"
                  type="text"
                  name="body"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Valor -->
            <b-form-group label="Valor">
              <validation-provider
                #default="{ errors }"
                name="price"
                rules="required|max:150"
              >
                <b-form-input
                  size="sm"
                  v-model="charge.price"
                  autocomplete="off"
                  placeholder="Valor"
                  type="number"
                  name="price"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

          </b-form>
          </validation-observer>
        </div>
      </div>
    </div>
    <footer class>
      <div class="row">
        <div class="col-12">
          <b-button
            size="sm"
            class="mr-1 float-right"
            variant="primary"
            @click="onSubmit"
          >
            Guardar
          </b-button>
          <b-button size="sm" class="mr-1 float-right" variant="outline-primary" @click="close">
            Cancelar
          </b-button>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput
} from "bootstrap-vue";
import OperationService from "@/services/OperationService";
import { required } from '@validations'

import CustomerService from "@/services/CustomerService";

export default {
  name: "ChargeEdit",
  components:{
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    onSave: Object,
    item: Object,
  },
  data() {
    return {
      required,
      provinces: [],
      charge: null,
      customers: [],
    };
  },
  methods: {

  /**
   *
   */
  onSubmit(event) {
      event.preventDefault();
      this.$refs.editChargeForm.validate().then((valid) => {
        if (valid) {
          OperationService.createCharge(this.charge, this.charge.id)
            .then(({ data }) => {
              this.$emit("close");
              this.onSave.handler(data);
              console.error(data);
            })
            .catch((error) => {
              console.error(error);
            });
        }
      });
    },

    /**
     *
     */
    close() {
      this.$emit("close");
    },

  },

  /**
   *
   */
  mounted() {

    /**
     *
     */
     CustomerService.index().then(({ data }) => {
      this.customers = data.data.map((d) => {
        return Object.assign(d, { fullText: d.brand, code: d.id });
      });
    });



    /**
     *
     */
    this.charge = { id: this.item.id };
  },

};
</script>

<style lang="scss" scoped>
.card-modal {
  height: 400px;
  display: block;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
